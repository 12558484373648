import React from "react"
import Layout from "../components/Layout";
import Sections from '../components/Sections';
import Seo from "../components/Seo2";
import Page from "../components/Page";

import data from '../data/chef.json';

const chef = () => {
  return <Layout>
      <Seo {...data.meta} />
      <Page {...data.page} />
      <Sections sections={data.sections} />
    </Layout>
}

export default chef
